/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/material-design-icons/iconfont/material-icons.css'; 
/* This import is for angular material icons. It imports the css file so that we can use all images downloaded in the npm package */

/* --------- STYLES - START --------- */
.apply-search {
    padding-bottom: 1.25em;  //padding nedded to make this centered due to the nature (built-in padding) of mat-form filters.
    justify-self: end;
    align-self: center;
}

body {
    margin: 0;
}

html {
    overflow-y: scroll;
  }

.global-margin {
    padding-left: 10.6em;
    padding-right: 10.6em;
}

.filter-section {
    background: #ECEFF1;  //Blue Grey 50
    padding-top: 1.25em;
}

.filters-to-content-separation {
    padding-top: 2em;
}

.bold-font{
    font-weight: bold;
}
/* --------- STYLES - END --------- */


/* --------- MEDIA QUERIES - START --------- */

//laptop or small desktop
@media (max-width: 1450px) {

    .global-margin {
        padding-left: 5em;
        padding-right: 5em;
    }
}

@media (max-width: 1349px) {
    .apply-search {
        padding-bottom: 1.25em;
        justify-self: center;
    }
}

//tablet
@media (max-width: 1080px) {

    .global-margin {
        padding-left: 2em;
        padding-right: 2em;
    }
}

//mobile or small tablet
@media (max-width: 680px) {

    .mat-form-field-infix {
        display: block;
        position: relative;
        flex: auto;
        min-width: 0;
    }

    .apply-search {
        padding-bottom: 1.25em;  //padding nedded to make this centered due to the nature (built-in padding) of mat-form filters.
        justify-self: stretch;
        align-self: center;
        margin: auto;
    }

    .global-margin {
        padding-left: 5em;
        padding-right: 5em;
    }
}

//mobile
@media (max-width: 550px) {

    .global-margin {
        padding-left: 2em;
        padding-right: 2em;
    }
}

/* --------- MEDIA QUERIES - END --------- */

